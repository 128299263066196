<template>
  <gw-dialog v-model="dialog" @close:dialog="onClose">
    <template #activator="{ on }">
      <v-btn :loading="loading" color="primary" v-on="on">
        {{ $t("generate") }}
      </v-btn>
    </template>

    <template #prepend-actions>
      <v-btn
        :disabled="total > 0"
        :loading="loading"
        color="primary"
        text
        @click="onGenerate"
      >
        {{ $t("generate") }}
      </v-btn>
    </template>

    <template v-if="total" #actions>
      <span
        class="font-weight-bold text-subtitle-2"
        v-text="$t('generating')"
      />
    </template>

    <template v-else #append-actions>
      <v-btn
        :loading="loading"
        class="mr-2"
        color="error"
        text
        @click="onRemove"
      >
        {{ $t("remove") }}
      </v-btn>

      <v-switch v-model="obFilters.all" :label="$t('all')" input-value="1" />
    </template>

    <v-form v-if="dialog" class="fill-height" @submit.prevent>
      <gw-form-observer
        :loading="loading"
        hide-bottom-actions
        hide-top-actions
        no-nest
        @cancel="onClose"
        @save="onGenerate"
      >
        <v-row>
          <v-col cols="12" lg="6">
            <accounting-movement-type-select
              v-model="obFilters.accounting_movement_type_id"
              :only-items="accountMovementTypeList"
              required
              with-all
            />
          </v-col>

          <v-col cols="12" lg="6">
            <date-range :value="dateRange" @input="onSetDates" />
          </v-col>
        </v-row>
      </gw-form-observer>
    </v-form>
  </gw-dialog>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import GwDialog from "@/components/common/GwDialog.vue";
import AccountingMovementTypeSelect from "@/modules/accountingentries/components/AccountingMovementTypeSelect.vue";
import {
  AccountingEntryCollection,
  AccountingEntryInvoice,
} from "@planetadeleste/vue-mc-gw";
import { isEmpty } from "lodash";
import DateRange from "@/components/form/fields/DateRange.vue";
import dayjs from "dayjs";
import type { AxiosRequestConfig } from "axios";
import { route } from "@/services/laroute";
import type { Channel } from "pusher-js";
import { EventBus } from "@/services/event-bus";
import { TYPE } from "vue-toastification";

interface AccountingEntryInvoiceGenerateResponse {
  total: number;
  batch_id: number;
}

@Component({
  components: { DateRange, AccountingMovementTypeSelect, GwDialog },
})
export default class AccountingEntryInvoiceGenerate extends Vue {
  dialog = false;
  loading = false;
  total = 0;
  obFilters: Record<string, any> = {};
  dateRange: string[] = [];
  obAccEntryCollection: AccountingEntryCollection =
    new AccountingEntryCollection();
  obMonitorProgressChannel: Channel | null = null;

  get accountMovementTypeList() {
    return this.obAccEntryCollection.map(
      "accounting_movement_type_id"
    ) as number[];
  }

  get sEventChannel() {
    return "accountingentry.progress.finish";
  }

  onSetDates(arValue: string[]) {
    this.dateRange = arValue;
    this.$set(this.obFilters, "created_at", arValue);
  }

  onClose() {
    this.dialog = false;
    this.total = 0;
  }

  async onGenerate() {
    this.loading = true;
    const obConfig: AxiosRequestConfig = {
      url: route("accountingentryinvoices.generate"),
      method: "POST",
      data: {
        filters: this.obFilters,
      },
    };

    const obModel = new AccountingEntryInvoice();
    const obResponse = await obModel.createRequest(obConfig).send();
    const sRespMessage = obResponse.getData().message;
    this.loading = false;

    if (sRespMessage) {
      this.$toast(sRespMessage);
    }

    const obData = obResponse.getData()
      .data as AccountingEntryInvoiceGenerateResponse;
    this.total = obData.total;
  }

  async onRemove() {
    const sMessage = this.$t("ask.remove.record");
    const bRes = await this.$confirm(sMessage as string, {
      color: "warning",
    });

    if (!bRes) {
      return;
    }

    this.loading = true;
    const obConfig: AxiosRequestConfig = {
      url: route("accountingentryinvoices.remove"),
      method: "POST",
      data: {
        filters: this.obFilters,
      },
    };

    const obModel = new AccountingEntryInvoice();
    const obResponse = await obModel.createRequest(obConfig).send();
    const sRespMessage = obResponse.getData().message;
    const sStatus = obResponse.getData().status;
    this.loading = false;

    if (sRespMessage) {
      this.$toast(sRespMessage, {
        type: sStatus ? TYPE.SUCCESS : TYPE.WARNING,
      });
    }

    EventBus.emit("reload.index");
    this.onClose();
  }

  created() {
    EventBus.on(this.sEventChannel, this.onClose);
  }

  beforeDestroy() {
    EventBus.off(this.sEventChannel, this.onClose);
  }

  async mounted() {
    if (isEmpty(this.accountMovementTypeList)) {
      this.loading = true;
      await this.obAccEntryCollection.list();
      this.loading = false;

      if (!this.obFilters.accounting_movement_type_id) {
        this.$set(
          this.obFilters,
          "accounting_movement_type_id",
          this.accountMovementTypeList[0]
        );
      }
    }

    if (!this.dateRange.length) {
      this.dateRange = [
        dayjs().startOf("month").format("YYYY-MM-DD"),
        dayjs().format("YYYY-MM-DD"),
      ];
    }
  }
}
</script>
